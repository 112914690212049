import React from 'react';
import TipTopLogo from "../assets/images/logo.jpeg"
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';
import { Container, Row, Col, Navbar, Form, Modal } from 'react-bootstrap';
import axios from 'axios';

export default function Login(props) {
  const [USR_EMAIL, setUsrEmail] = React.useState("")
  const [USR_PASSWORD, setUsrPass] = React.useState("")

  const [show, setShow] = React.useState(false);
  const [FST_NAME, setFstName] = React.useState("")
  const [LST_NAME, setLstName] = React.useState("")
  const [NEW_EMAIL, setNewEmail] = React.useState("")
  const [NEW_PASSWORD, setNewPass] = React.useState("")
  const [CFM_PASSWORD, setCfmPass] = React.useState("")
  const handleClose = () => {
    setFstName("");
    setLstName("");
    setNewEmail("");
    setNewPass("");
    setCfmPass("");
    setShow(false);
  }
  const registerAccount = () => {
    props.setLoader(true)
    axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/common/RegisterUser.php`, {
      USR_ID: "",
      USR_EMAIL: NEW_EMAIL,
      FIRST_NAME: FST_NAME,
      LAST_NAME: LST_NAME,
      USR_PASS: NEW_PASSWORD,
      USR_ROLE: "2",
      USR_STATUS: "Inactive"
    }).then(response => {
      props.setLoader(false)
      if (response.data.status === 200) {
        handleClose();
      } else {
        window.alert(response.data.message);
      }
    }).catch(error => {
      props.setLoader(false)
      console.error(error);
    });
  }

  const handleShow = () => {
    setShow(true)
  };

  const navigate = useNavigate();
  const handleValidate = () => {
    if (USR_EMAIL === "" || USR_PASSWORD === "") {
      props.setLoader(false)
      props.setAlert(true, "Error", "Please fill email or password first", "error")
      return false;
    }
    return true;
  }
  const handleLogin = () => {
    props.setLoader(true)
    if (!handleValidate()) return;
    axios.post(`${process.env.REACT_APP_BASE_URL}services/query/system/Login.php`, {
      USR_EMAIL,
      USR_PASSWORD
    }).then(res => {
      props.setLoader(false)
      if (+res.data.success === 1) {
        let respData = res.data.data
        props.setAlert(true, "Success", "You are Successfully login!!", "success")
        localStorage.setItem('token', respData.USR_TOKEN ? true : false);
        localStorage.setItem('FIRST_NAME', respData.FIRST_NAME);
        localStorage.setItem('LAST_NAME', respData.LAST_NAME);
        localStorage.setItem('USR_EMAIL', respData.USR_EMAIL);
        localStorage.setItem('USR_ROLE', respData.USR_ROLE);
        localStorage.setItem('USR_ID', respData.USR_ID);
        let trgLink = respData.USR_ROLE === "1" ? '/dashboard' : 'customerDashboard';
        navigate(trgLink, {
          replace: true
        });

      } else {
        props.setAlert(true, "Error", res.data.message, "error")
      }

    }).catch(err => {
      props.setLoader(false)
      console.error('err :>> ', err);
    })
  }


  return (
    <div style={{
      background: "#fffca8",
      height: "100vh"
    }} >
      <Container fluid className='login-container'>
        <Row style={{ justifyContent: "center" }} >
          <Col md={5} className='p-2' style={{
            // border: "1px solid gray", background: "white", borderRadius: "6px"
            borderRadius: "50px",
            background: "linear-gradient(145deg, #e6e397, #ffffb4)",
            boxShadow: "8px 8px 16px #d4d18b, -8px -8px 16px #ffffc5"
          }} >
            <Navbar style={{ justifyContent: "center" }} >
              <img
                src={TipTopLogo}
                height="60"
                className="d-inline-block align-top"
                alt="Tip Top Toys logo"
              />
            </Navbar>
            <div className="p-3" >
              <div className="mb-3" style={{ textAlign: "center" }} >
                <h4>Login</h4>
              </div>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  name={"USR_EMAIL"}
                  value={USR_EMAIL}
                  autoComplete='new-password'
                  placeholder='User Name'
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  onChange={(evt) => setUsrEmail(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  autoComplete='new-password'
                  placeholder='Password'
                  name={"USR_PASSWORD"}
                  value={USR_PASSWORD}
                  // onKeyPress={this.handleKeyPress.bind(this)}
                  onChange={(evt) => setUsrPass(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
              </Form.Group>
            </div>
            <div className="mb-3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} >
              <p style={{ paddingLeft: 15, color: "#1ca8f0", cursor: "pointer" }} onClick={handleShow}>Register account</p>
              <p style={{ paddingRight: 15 }}>forgot password ?</p>
            </div>
            <div style={{ margin: "0px 50px" }} >
              <Button variant='contained' color='primary' style={{ width: "100%" }} onClick={handleLogin} > Login </Button>
            </div>
          </Col>
        </Row>
        <Modal backdrop={false} show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Enter Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  name={"FST_NAME"}
                  value={FST_NAME}
                  placeholder='First Name'
                  onChange={(evt) => setFstName(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
                <br></br>
                <Form.Control
                  type="text"
                  name={"LST_NAME"}
                  value={LST_NAME}
                  placeholder='Last Name'
                  onChange={(evt) => setLstName(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
                <br></br>
                <Form.Control
                  type="email"
                  name={"NEW_EMAIL"}
                  value={NEW_EMAIL}
                  placeholder='Email Address'
                  onChange={(evt) => setNewEmail(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
                <br></br>
                <Form.Control
                  type="password"
                  name={"NEW_PASSWORD"}
                  value={NEW_PASSWORD}
                  placeholder='Enter Password'
                  onChange={(evt) => setNewPass(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
                <br></br>
                <Form.Control
                  type="password"
                  name={"CFM_PASSWORD"}
                  value={CFM_PASSWORD}
                  placeholder='Confirm Password'
                  onChange={(evt) => setCfmPass(evt.target.value)}
                  style={{ padding: "0.25rem" }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='contained' style={{ width: "100%" }} onClick={registerAccount}>
              Register Account
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}