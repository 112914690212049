import React, { Component } from 'react';
// import ReactDOM from 'react-dom/client';
import { Container, Row, Col } from 'react-bootstrap';
import { Receipt, Inventory, Accessibility, Category } from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar, Cell } from 'recharts';

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];


function CardUI({ value, cardColor, icon, label }) {
    return (
        <div style={{
            background: cardColor,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "10px",
            color: "#ffffff",
            borderRadius: "6px",
            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"

        }} >
            <div>
                <h5>{value}</h5>
                <h6>{label}</h6>
            </div>
            <div>
                {icon}
            </div>
        </div>
    )
}

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col><CardUI icon={<Category fontSize='large' />} value={"100"} label={"Product"} cardColor="#fe9f4f" /></Col>
                    <Col><CardUI icon={<Receipt fontSize='large' />} value={"105"} label={"Sale Invoice"} cardColor="#23cfe7" /></Col>
                    {/* <Col><CardUI icon={<Accessibility fontSize='large' />} value={"149"} label={"Vendor"} cardColor="#1b294e" /></Col> */}
                    <Col><CardUI icon={<Inventory fontSize='large' />} value={"102"} label={"Inventory"} cardColor="#35c673" /></Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <div style={{
                            width: '100%', height: 300,
                            padding: "10px",
                            borderRadius: "6px",
                            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"

                        }}  >
                            <ResponsiveContainer>
                                <LineChart data={data}>
                                    <Legend />
                                    <Line type="monotone" dataKey="pv" stroke="#8884d8" strokeWidth={2} />
                                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" strokeWidth={2} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                    <Col>
                        <div style={{
                            width: '100%', height: 300,
                            padding: "10px",
                            borderRadius: "6px",
                            boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)"
                        }}  >
                            <ResponsiveContainer>
                                <BarChart data={data} >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                                    <Bar dataKey="amt" stackId="a" fill="#82ca9d" />
                                    <Bar dataKey="uv" fill="#ffc658" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </Col>
                </Row>

            </Container>
        )
    }
}