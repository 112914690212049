import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './../pages/Error';
import { ProtectedRoutes, PublicRoutes, ProtectedRoutesV2 } from './../routes/VerifyRoutes';
import Login from './../pages/Login';
import AppLayout from './../layout/AppLayout';
// import Vendor from './../container/setup/Vendor';
import AddProduct from './../container/products/AddProduct';
import Inventory from './../container/products/Inventory';
// import Shelves from './../container/setup/Shelves';
import Dashboard from './../container/main/Dashboard';
import CustomerReq from './../container/registration/CustomerReq';
import Loader from "./../helper/Loader";
import ToastAlert from '../helper/ToastAlert';
import MoveProduct from '../container/products/MoveProduct';
import SaleInvoice from '../container/finance/SaleInvoice';

export default function AppRoute() {
    const [showLoader, setLoader] = React.useState(false);
    const [toastObj, setToastObj] = React.useState(
        {
            varient: "",
            title: "",
            message: "",
            showAlertProp: false
        }
    );

    const router = createBrowserRouter([
        {
            path: "/",
            errorElement: <ErrorPage />,
            element: <PublicRoutes />,
            children: [{
                path: "/",
                element: <Login setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} />,
            }]
        },
        {
            element: <ProtectedRoutes />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/dashboard",
                    element: <AppLayout><Dashboard setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                {
                    path: "/product",
                    element: <AppLayout><AddProduct setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                {
                    path: "/inventory",
                    element: <AppLayout><Inventory setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                {
                    path: "/moveProduct",
                    element: <AppLayout><MoveProduct setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                {
                    path: "/saleInvoice",
                    element: <AppLayout><SaleInvoice setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                // {
                //     path: "/shelve",
                //     element: <AppLayout><Shelves /></AppLayout>,
                // },
                // {
                //     path: "/vendor",
                //     element: <AppLayout><Vendor /></AppLayout>,
                // },
                {
                    path: "/customerRequest",
                    element: <AppLayout><CustomerReq setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                }
            ],
        },
        {
            element: <ProtectedRoutesV2 />,
            errorElement: <ErrorPage />,
            children: [
                {
                    path: "/customerDashboard",
                    element: <AppLayout><Dashboard setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                },
                {
                    path: "/customerProduct",
                    element: <AppLayout><AddProduct setLoader={(props) => setLoader(props)} setAlert={(...props) => { handleChangeToast(...props) }} /></AppLayout>,
                }
            ],
        },
    ], { basename: "/" },
    );


    const handleChangeToast = (showAlertProp = false, title = "", message = "", varient = "") => {
        setToastObj(prevState => ({
            ...prevState,
            varient,
            title,
            message,
            showAlertProp
        }))
    }

    return (
        <>
            {showLoader && <Loader />}
            <RouterProvider router={router} />
            <ToastAlert
                setAlert={() => handleChangeToast(false)}
                varient={toastObj.varient}
                title={toastObj.title}
                message={toastObj.message}
                showAlertProp={toastObj.showAlertProp}
            />
        </>
    )
}