import './../assets/css/SaleInvoiceStyle.css'
import React, { Component } from "react";

class SaleInvoiceReport extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { data } = this.props
        let shopDetails = ""
        if (data.OTHER_DATA) {
            try {
                shopDetails = JSON.parse(data.OTHER_DATA);

            } catch (error) {
                console.error('error :>> ', error);
            }
        }
        return (
            <div id="spnPrintThermal" size="80mm">
                <section>
                    <div style={{ textAlign: 'center', display: "flex", justifyContent: "space-between", padding: "10px", background: "black", color: "white" }} >
                        <h6>Sales Receipt</h6>
                        {/* <span style={{ fontWeight: 'bold', fontSize: '16px' }}>#. {3456347782}</span> */}
                    </div>
                    <div className="main-prnthead">
                        <div style={{ textAlign: 'center', color: 'black' }} >
                            <h4>Tip Top Toys</h4>
                            {/* <h4>{shopDetails["SP_NAME"]}</h4> */}
                            {/* <div>{shopDetails["SP_ADDRESS"]}</div>
                            <div>{shopDetails["SP_MANAGER_NUM"]}</div> */}
                        </div>
                    </div>

                    <div style={{ display: "flex", fontSize: '11px' }}>
                        <div className="row main-info" >
                            <div>
                                Serve By:
                            </div>
                            <h6>Admin</h6>
                            <div>
                                Serve To:
                            </div >
                            <h6>{data.SaleTo}</h6>
                        </div >


                        <div className="row main-info" style={{ textAlign: "right" }}>
                            <div>
                                Sales Date:
                            </div>
                            <h6>{data.SaleDate}</h6>

                            <div>
                                Print Date:
                            </div>
                            <h6>{new Date().toLocaleDateString()}</h6>
                        </div>
                    </div>

                    <div className="text-center" >-----------------------------------</div>
                    <div className="clearfix"></div>
                    <div className="col-md-12"
                        style={{ paddingRight: '12px', paddingLeft: '12px', paddingBottom: '7px' }}>
                        <table className="tbl-details" align="center">
                            <tr>
                                <th>Qty</th>
                                <th>Name</th>
                                <th>Rate</th>
                                <th>Amount</th>
                            </tr>

                            {data.ItemList?.map((ele, index) => {
                                return (
                                    <tr key={index} >
                                        <td>{ele.itemQty}</td>
                                        <td>{ele.itemName}</td>
                                        <td>{ele.itemPrice}</td>
                                        <td className="text-right">£ {ele.itemTotAmt}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </div >
                    <div className="col-md-12" style={{ padding: '12px', paddingTop: '0px' }}>
                        <table className="tbl-amount" align="right">
                            {/* <tr>
                                <td className="text-right">Vat:</td>
                                <td>0.0</td>
                            </tr> */}
                            <tr>
                                <td className="text-right">Grand Total:</td>
                                <td>£ {data.TotalAmount}</td>
                            </tr>
                            {/* <tr>
                                <td className="text-right">Payment:</td>
                                <td>0.0</td>
                            </tr> */}
                            {/* <tr>
                                <td className="text-right">Balance:</td>
                                <td>0.0</td>
                            </tr> */}
                        </table>
                    </div>
                    <div className="clearfix"></div>
                    <div className="text-center" >-----------------------------------</div>
                    <p style={{ fontSize: '10px', fontWeight: 'bold', textAlign: 'justify', marginBottom: '0px', whiteSpace: 'pre-wrap' }}>{shopDetails["SP_PRINT_CONTENT"]}</p>
                    <div className="clearfix"></div>
                </section>
            </div >
        );
    }
}

export default SaleInvoiceReport;