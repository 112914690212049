import React, { Component } from "react"
// import TipTopLogo from "../assets/images/logo.jpeg"
import { Logout, Settings } from '@mui/icons-material';
import { Container, Row, Col, Navbar } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Avatar, IconButton, Menu, MenuItem, Divider, ListItemIcon } from "@mui/material";

class AppLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabPath: window.location.pathname,
            USR_ROLE: localStorage.getItem('USR_ROLE'),
            showMenu: false
        }
    }

    handleSwitchTab(tabPath) {
        this.setState({ tabPath })
    }

    handleSignOut() {
        localStorage.removeItem('token');
        window.location.assign('/')
    }

    render() {
        let { tabPath, USR_ROLE } = this.state;
        return (
            <div>
                <Navbar style={{ height: "10vh", boxShadow: "#dee2e6 0px 2px 5px" }} className="bg-body-tertiary">
                    <Container fluid >
                        <Navbar.Brand>Tip Top Toys</Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <IconButton
                                    onClick={(evt) => {
                                        this.setState({ showMenu: true })
                                    }}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={this.state.showMenu ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={this.state.showMenu ? 'true' : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                                </IconButton>
                                <Menu
                                    id="account-menu"
                                    open={this.state.showMenu}
                                    onClose={() => this.setState({ showMenu: false })}
                                    onClick={() => this.setState({ showMenu: false })}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                >
                                    <MenuItem onClick={() => this.setState({ showMenu: false })}>
                                        <ListItemIcon>
                                            <Settings fontSize="small" />
                                        </ListItemIcon>
                                        Setting
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem onClick={this.handleSignOut.bind(this)}>
                                        <ListItemIcon>
                                            <Logout fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Menu>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <Container fluid>
                    <Row style={{ height: "90vh" }} >
                        <Col md={2} style={{ height: "100%", overflow: "auto", backgroundColor: "#f8f9fa" }} >

                            {USR_ROLE === "1" ?
                                <>
                                    <div>
                                        <div>Menu</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/dashboard'} ><div onClick={this.handleSwitchTab.bind(this, "/dashboard")} className={tabPath === "/dashboard" ? "tab-active p-1" : "p-1"} >Dashboard</div></Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div>Products</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/product'} ><div onClick={this.handleSwitchTab.bind(this, "/product")} className={tabPath === "/product" ? "tab-active p-1" : "p-1"} >Products</div></Link>
                                            <Link className="sidenav-tabs" to={'/inventory'} ><div onClick={this.handleSwitchTab.bind(this, "/inventory")} className={tabPath === "/inventory" ? "tab-active p-1" : "p-1"} >Inventory</div></Link>
                                            {/* <Link className="sidenav-tabs" to={'/moveProduct'} ><div onClick={this.handleSwitchTab.bind(this, "/moveProduct")} className={tabPath === "/moveProduct" ? "tab-active p-1" : "p-1"} >Move Product</div></Link> */}
                                        </div>
                                    </div>
                                    <div>
                                        <div>Finance</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/saleInvoice'} ><div onClick={this.handleSwitchTab.bind(this, "/saleInvoice")} className={tabPath === "/saleInvoice" ? "tab-active p-1" : "p-1"} >Sale Invoice</div></Link>
                                            {/* <Link className="sidenav-tabs" to={'/moveProduct'} ><div onClick={this.handleSwitchTab.bind(this, "/moveProduct")} className={tabPath === "/moveProduct" ? "tab-active p-1" : "p-1"} >Move Product</div></Link> */}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <div>Setup</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/shelve'} ><div onClick={this.handleSwitchTab.bind(this, "/shelve")} className={tabPath === "/shelve" ? "tab-active p-1" : "p-1"} >Shelves</div></Link>
                                            <Link className="sidenav-tabs" to={'/vendor'} ><div onClick={this.handleSwitchTab.bind(this, "/vendor")} className={tabPath === "/vendor" ? "tab-active p-1" : "p-1"} >Vendor</div></Link>
                                        </div>
                                    </div> */}
                                    <div>
                                        <div>Registration</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/customerRequest'} ><div onClick={this.handleSwitchTab.bind(this, "/customerRequest")} className={tabPath === "/customerRequest" ? "tab-active p-1" : "p-1"} >Customer Request</div></Link>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                            }
                            {USR_ROLE === "2" ?
                                <>
                                    <div>
                                        <div>Menu</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/customerDashboard'} ><div onClick={this.handleSwitchTab.bind(this, "/customerDashboard")} className={tabPath === "/customerDashboard" ? "tab-active p-1" : "p-1"} >Dashboard</div></Link>
                                        </div>
                                    </div>
                                    <div>
                                        <div>Products</div>
                                        <div style={{ marginLeft: "10px" }} >
                                            <Link className="sidenav-tabs" to={'/customerProduct'} ><div onClick={this.handleSwitchTab.bind(this, "/customerProduct")} className={tabPath === "/customerProduct" ? "tab-active p-1" : "p-1"} >Products</div></Link>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                            }

                        </Col>
                        <Col className="p-4" md={10} style={{ height: "100%", overflow: "auto", backgroundColor: "#fff" }} >
                            {this.props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AppLayout;