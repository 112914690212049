import React from 'react';
import { useBarcode } from 'react-barcodes';

function Barcode(props) {

    const { inputRef } = useBarcode(
        {
            value: props.value,
            options: {
                width: props.width || 1,
                height: props.height || 60,
                displayValue: props.displayValue || true,
                text: props.text || "",
                fontOptions: props.fontOptions || "",
                font: props.font || "monospace",
                textAlign: props.textAlign || "center",
                textPosition: props.textPosition || "bottom",
                textMargin: props.textMargin || 0,
                fontSize: props.fontSize || 15,
                background: props.background || "#ffffff",
                lineColor: props.lineColor || "#000000",
                margin: props.margin || 10,
                marginTop: props.marginTop || 0,
                marginBottom: props.marginBottom || 0,
                marginLeft: props.marginLeft || 0,
                marginRight: props.marginRight || 0
            }
        });

    return <svg ref={inputRef} />;
};

export default Barcode;