import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AppRoute from './routes/AppRoute';

const theme = createTheme({
  typography: {
    fontFamily: [
      "Comic Sans MS",
      "cursive"
    ].join(','),
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme} >
    <AppRoute />
  </ThemeProvider>
);

