import React, { Component } from 'react';
import { Form, InputGroup, Container, Row, Col } from 'react-bootstrap';
import Grid from '../../components/Grid';
import { Button } from '@mui/material';
import axios from 'axios';

export default class CustomerReq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            UserData: [],
            firstName: "",
            lastName: "",
            emailAddress: "",
            setPassword: "",
            confirmPassword: "",
            USR_ID: "",
            USR_STATUS: "Active"
        }
    }

    componentDidMount() {
        this.getCustomerRequest()
    }

    getCustomerRequest() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/common/GetCustomerRegistration.php`, {
            USR_ROLE: "2"
        }).then(res => {
            let UserData = []
            if (res.data.success === 1) {
                UserData = res.data.data
            }
            this.setState({ UserData })
            this.props.setLoader(false)
        }).catch(err => {
            this.props.setLoader(false)
            console.log('err :>> ', err);
        })
    }

    handleEdit(obj) {
        if (obj) {
            this.setState({
                showForm: true,
                USR_ID: obj.USR_ID,
                emailAddress: obj.USR_EMAIL,
                firstName: obj.FIRST_NAME,
                lastName: obj.LAST_NAME,
                setPassword: "",
                confirmPassword: "",
                USR_STATUS: obj.USR_STATUS
            })
        }
    }

    handleClear() {
        this.setState({
            firstName: "",
            lastName: "",
            emailAddress: "",
            setPassword: "",
            confirmPassword: "",
            USR_ID: "",
            USR_STATUS: "Active"
        })
    }

    handleChange(fieldName, evt) {
        this.setState({ [fieldName]: evt.target.value })
    }

    handleSubmit(evt) {
        let formData = {
            USR_ID: this.state.USR_ID,
            USR_EMAIL: this.state.emailAddress,
            FIRST_NAME: this.state.firstName,
            LAST_NAME: this.state.lastName,
            USR_PASS: this.state.setPassword,
            USR_ROLE: "2",
            USR_STATUS: this.state.USR_STATUS
        };
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/common/RegisterUser.php`, formData).then(response => {
            this.props.setLoader(false)
            if (response.data.status === 200) {
                this.props.setAlert(true, "Success", response.data.message, "success")
                this.handleClear();
                this.getCustomerRequest();
                this.setState({ showForm: false })
            } else {
                window.alert(response.data.message);
            }
        }).catch(error => {
            this.props.setLoader(false)
            console.error(error);
        });

    }

    render() {
        let {
            showForm,
            firstName,
            lastName,
            emailAddress,
            setPassword,
            confirmPassword,
            USR_STATUS
        } = this.state;
        return (
            <Container>
                {!showForm ?
                    <Grid
                        insertData={() => this.setState({ showForm: true })}
                        buttonTitle="Create Customer"
                        handleEdit={(obj) => this.handleEdit.bind(this, obj)}
                        columnAlt={["First Name", "last Name", "Email", "Status"]}
                        columnTitle={["FIRST_NAME", "LAST_NAME", "USR_EMAIL", "USR_STATUS"]}
                        columnData={this.state.UserData}
                    />
                    :
                    <div>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">First Name</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name={"FST_NAME"}
                                        placeholder='First Name'
                                        value={firstName}
                                        onChange={this.handleChange.bind(this, "firstName")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Last Name</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name={"LST_NAME"}
                                        placeholder='Last Name'
                                        value={lastName}
                                        onChange={this.handleChange.bind(this, "lastName")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Email Address</InputGroup.Text>
                                    <Form.Control
                                        type="email"
                                        name={"NEW_EMAIL"}
                                        placeholder='Email Address'
                                        value={emailAddress}
                                        onChange={this.handleChange.bind(this, "emailAddress")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Enter Password</InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        name={"NEW_PASSWORD"}
                                        autoComplete='new-password'
                                        placeholder='Enter Password'
                                        value={setPassword}
                                        onChange={this.handleChange.bind(this, "setPassword")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Confirm Password</InputGroup.Text>
                                    <Form.Control
                                        type="password"
                                        name={"CFM_PASSWORD"}
                                        placeholder='Confirm Password'
                                        value={confirmPassword}
                                        onChange={this.handleChange.bind(this, "confirmPassword")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Status</InputGroup.Text>
                                    <Form.Select defaultValue={USR_STATUS} onChange={this.handleChange.bind(this, "USR_STATUS")}>
                                        <option value={"Active"}>Active</option>
                                        <option value={"Inactive"}>In-Active</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Button onClick={() => { this.handleClear(); this.setState({ showForm: false }) }} style={{ marginRight: "4px" }} variant="outlined">Cancel</Button>
                                <Button onClick={this.handleSubmit.bind(this)} style={{ marginLeft: "4px" }} variant="contained" color="success">Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container >
        )
    }
}