import React, { Component } from 'react';
import { Form, InputGroup, Container, Row, Col, Image } from 'react-bootstrap';
import Grid from '../../components/Grid';
import { Button } from '@mui/material';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import Barcode from '../../components/Barcode'
import ProductImage from '../../assets/images/ProductImage.png'

export default class AddProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            ProductList: [],
            itemImage: null,
            itemID: "",
            itemName: "",
            itemCode: "",
            itemCategory: "",
            itemStatus: "Available",
            itemType: "",
            itemPrice: "",
            sellPrice: "",
            itemPerCTN: ""
        }
    }

    componentDidMount() {
        this.getAllProduct()
    }

    getAllProduct() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/products/GetAllProducts.php`).then(res => {
            let ProductList = []
            if (res.data.success === 1) {
                ProductList = res.data.data
            }
            this.setState({ ProductList })
            this.props.setLoader(false)
        }).catch(err => {
            this.props.setLoader(false)
            console.log('err :>> ', err);
        })
    }

    handleChange(fieldName, evt) {
        this.setState({ [fieldName]: evt.target.value })
    }

    handleFileUpload(fieldName, evt) {
        let files = evt.target.files[0]
        this.setState({ [fieldName]: files })
    }

    handleClear() {
        this.setState({
            itemImage: null,
            itemID: "",
            itemName: "",
            itemCode: "",
            itemCategory: "",
            itemStatus: "Available",
            itemType: "",
            itemPrice: "",
            sellPrice: "",
            itemPerCTN: ""
        })
    }


    handleEdit(obj) {
        if (obj) {
            this.setState({
                showForm: true,
                itemImage: obj.ITEM_IMG_PATH !== "" ? `${process.env.REACT_APP_BASE_URL}store/products/${obj.ITEM_IMG_PATH}` : null,
                itemID: obj.ITEM_ID,
                itemName: obj.ITEM_NAME,
                itemCode: obj.ITEM_CODE,
                itemCategory: obj.ITEM_CATEGORY,
                itemStatus: obj.ITEM_STATUS,
                itemType: obj.ITEM_TYPE,
                itemPrice: obj.BASIC_PRICE,
                sellPrice: obj.SELL_PRICE,
                itemPerCTN: obj.PER_CTN_QTY
            })
        }
    }

    selectDoc() {
        document.getElementById("imageUpload").click();
    }

    handleSubmit(evt) {
        let testData = new FormData();
        testData.append('itemImage', this.state.itemImage)
        testData.append('itemID', this.state.itemID)
        testData.append('itemName', this.state.itemName)
        testData.append('itemCode', this.state.itemCode)
        testData.append('itemCategory', this.state.itemCategory)
        testData.append('itemStatus', this.state.itemStatus)
        testData.append('itemType', this.state.itemType)
        testData.append('itemPrice', this.state.itemPrice)
        testData.append('sellPrice', this.state.sellPrice)
        testData.append('itemPerCTN', this.state.itemPerCTN)

        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/products/AddProduct.php`, testData).then(response => {
            this.props.setLoader(false)
            if (response.data.status === 200) {
                this.props.setAlert(true, "Success", response.data.message, "success")
                this.handleClear();
                this.getAllProduct();
                this.setState({ showForm: false })
            } else {
                window.alert(response.data.message);
            }
        }).catch(error => {
            this.props.setLoader(false)
            console.error(error);
        });
    }

    render() {
        let {
            showForm,
            itemID,
            itemImage,
            itemName,
            itemCode,
            itemCategory,
            itemStatus,
            itemType,
            itemPrice,
            sellPrice,
            itemPerCTN
        } = this.state;
        return (
            <Container>
                {!showForm ?
                    <Grid
                        insertData={() => this.setState({ showForm: true })}
                        handleEdit={(obj) => this.handleEdit.bind(this, obj)}
                        buttonTitle="Add Products"
                        columnAlt={["Image", "Name", "Description", "Code", "Category", "Status", "Price", "Item / CTN"]}
                        columnTitle={["ITEM_IMG_PATH", "ITEM_NAME", "ITEM_TYPE", "ITEM_CODE", "ITEM_CATEGORY", "ITEM_STATUS", "BASIC_PRICE", "PER_CTN_QTY"]}
                        columnData={this.state.ProductList}
                    />
                    :
                    <div>
                        <Row>
                            <Col md={6} >
                                <InputGroup>
                                    <InputGroup.Text id="formFile">Item Image</InputGroup.Text>
                                    <Form.Control id="imageUpload" style={{ display: "none" }} type="file" onChange={this.handleFileUpload.bind(this, "itemImage")} accept="image/*" />
                                    <div style={{ height: "30vh", width: "100%", background: "#8080802e", display: "flex", padding: "10px", justifyContent: "center", border: "1px dashed gray" }}>
                                        <Image style={{ height: "100%", width: "50%", cursor: "pointer" }}
                                            onClick={this.selectDoc.bind(this)}
                                            src={itemImage && typeof itemImage === 'string' ? itemImage : itemImage && typeof itemImage === 'object' ? URL.createObjectURL(itemImage) : ProductImage}
                                            alt='Product Image' />
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col md={6} >
                                <InputGroup>
                                    <InputGroup.Text id="formFile">Item Barcode</InputGroup.Text>
                                    {/* <Form.Control id="imageUpload" style={{ display: "none" }} type="file" onChange={this.handleFileUpload.bind(this, "itemImage")} accept="image/*" /> */}
                                    <div style={{ height: "30vh", width: "100%", background: "#8080802e", display: "flex", padding: "10px", justifyContent: "center", border: "1px dashed gray", alignItems: "center" }}>
                                        <div ref={el => (this.printLabel = el)} >
                                            <Barcode
                                                value={itemCode || 'RAUL-0001'}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: "0",
                                                right: "0",
                                                padding: "5px"
                                            }}
                                        >
                                            <ReactToPrint
                                                trigger={() => { return <Button variant="contained" color="secondary">Print</Button>; }}
                                                content={() => this.printLabel}
                                            />
                                        </div>

                                    </div>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col md={8}>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Name</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Name"
                                        aria-label="Item Name"
                                        aria-describedby="basic-addon1"
                                        value={itemName}
                                        onChange={this.handleChange.bind(this, "itemName")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Code</InputGroup.Text>
                                    <Form.Control
                                        placeholder="RAUL-0001"
                                        aria-label="Item Code"
                                        aria-describedby="basic-addon1"
                                        value={itemCode}
                                        onChange={this.handleChange.bind(this, "itemCode")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Desciption</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Desciption"
                                        aria-label="Item Desciption"
                                        aria-describedby="basic-addon1"
                                        value={itemType}
                                        onChange={this.handleChange.bind(this, "itemType")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Status</InputGroup.Text>
                                    <Form.Select defaultValue={itemStatus} onChange={this.handleChange.bind(this, "itemStatus")}>
                                        <option value={"Available"}>Available</option>
                                        <option value={"Not available"}>Not available</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Category</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Category"
                                        aria-label="Item Category"
                                        aria-describedby="basic-addon1"
                                        value={itemCategory}
                                        onChange={this.handleChange.bind(this, "itemCategory")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Basic Price</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Basic Price"
                                        aria-label="Basic Price"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={itemPrice}
                                        onChange={this.handleChange.bind(this, "itemPrice")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Per CTN </InputGroup.Text>
                                    <Form.Control
                                        placeholder="Total items available in 1 CTN"
                                        aria-label="Item Per CTN"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={itemPerCTN}
                                        onChange={this.handleChange.bind(this, "itemPerCTN")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <Button onClick={() => { this.handleClear(); this.setState({ showForm: false }) }} style={{ marginRight: "4px" }} variant="outlined">Cancel</Button>
                                <Button onClick={this.handleSubmit.bind(this)} style={{ marginLeft: "4px" }} variant="contained" color="success">Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container >
        )
    }
}