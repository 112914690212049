import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoutes = () => {
    // TODO: Use authentication token
    let localStorageToken = localStorage.getItem("token");
    let USR_ROLE = localStorage.getItem('USR_ROLE');
    let isTrueSet = (localStorageToken === 'true' && USR_ROLE === "1");
    return isTrueSet ? <Outlet /> : <Navigate to="/" replace />;
};

export const ProtectedRoutesV2 = () => {
    // TODO: Use authentication token
    let localStorageToken = localStorage.getItem("token");
    let USR_ROLE = localStorage.getItem('USR_ROLE');
    let isTrueSet = (localStorageToken === 'true' && USR_ROLE === "2");
    return isTrueSet ? <Outlet /> : <Navigate to="/" replace />;
};


export const PublicRoutes = () => {
    // TODO: Use authentication token
    let localStorageToken = localStorage.getItem("token");
    let USR_ROLE = localStorage.getItem('USR_ROLE');
    let isTrueSet = (localStorageToken === 'true' && USR_ROLE);
    let trgLink = USR_ROLE === "1" ? '/dashboard' : 'customerDashboard';
    return isTrueSet ? <Navigate to={trgLink} replace /> : <Outlet />;
};


// export default ProtectedRoutes;