import React, { Component } from 'react';
import { Form, InputGroup, Container, Row, Col } from 'react-bootstrap';
import Grid from '../../components/Grid';
import { Button } from '@mui/material';

const TableData = [{
    Image: "1",
    Name: "1",
    Code: "1",
    Category: "1",
    Status: "1",
    Type: "1",
    Basic_Price: "1",
    Sell_Price: "1",
    Total_CTN: "1",
    Item_In_CTN: "1",
    Total_Quantity: "1",
    Minimun_Stock_Available: "1"
},
{
    Image: "1",
    Name: "1",
    Code: "1",
    Category: "1",
    Status: "1",
    Type: "1",
    Basic_Price: "1",
    Sell_Price: "1",
    Total_CTN: "1",
    Item_In_CTN: "1",
    Total_Quantity: "1",
    Minimun_Stock_Available: "1"

}]

export default class MoveProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            itemName: "",
            itemCode: "",
            itemCategory: "",
            itemStatus: "",
            itemType: "",
            itemPrice: "",
            sellPrice: "",
            Available_Quantity: "",
            Select_Quantity: ""
        }
    }

    handleSave() {
        if (this.state.Select_Quantity > this.state.Available_Quantity) {
            this.props.setAlert(true, "Selected Quantity Can Not Be More Than Available Quantity", "error")
        }
        this.setState({ showForm: false })
    }

    handleChange(fieldName, evt) {
        this.setState({ [fieldName]: evt.target.value })
    }

    render() {
        let {
            showForm,
            itemName,
            itemCode,
            itemCategory,
            itemStatus,
            itemType,
            itemPrice,
            sellPrice,
            Available_Quantity,
            Select_Quantity
        } = this.state;
        return (
            <Container>
                {!showForm ?
                    <Grid
                        insertData={() => this.setState({ showForm: true })}
                        buttonTitle="Create move"
                        columnAlt={["Image", "Name", "Code", "Category", "Status", "Type", "Basic Price", "Sell Price", "Available Quantity", "Select Quantity"]}
                        columnTitle={["Image", "Name", "Code", "Category", "Status", "Type", "Basic_Price", "Sell_Price", "Available_Quantity", "Select_Quantity"]}
                        columnData={TableData}
                    />
                    :
                    <div>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Name</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Name"
                                        aria-label="Item Name"
                                        aria-describedby="basic-addon1"
                                        value={itemName}
                                        onChange={this.handleChange.bind(this, "itemName")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Code</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Code"
                                        aria-label="Item Code"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={itemCode}
                                        onChange={this.handleChange.bind(this, "itemCode")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Category</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Category"
                                        aria-label="Item Category"
                                        aria-describedby="basic-addon1"
                                        value={itemCategory}
                                        onChange={this.handleChange.bind(this, "itemCategory")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Status</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Status"
                                        aria-label="Item Status"
                                        aria-describedby="basic-addon1"
                                        value={itemStatus}
                                        onChange={this.handleChange.bind(this, "itemStatus")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Type</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Type"
                                        aria-label="Item Type"
                                        aria-describedby="basic-addon1"
                                        value={itemType}
                                        onChange={this.handleChange.bind(this, "itemType")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Basic Price</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Basic Price"
                                        aria-label="Basic Price"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={itemPrice}
                                        onChange={this.handleChange.bind(this, "itemPrice")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Sell Pice</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Sell Price"
                                        aria-label="Sell Price"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={sellPrice}
                                        onChange={this.handleChange.bind(this, "sellPrice")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Available Quantity</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Available Quantity"
                                        aria-label="Available Quantity"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={Available_Quantity}
                                        onChange={this.handleChange.bind(this, "Available_Quantity")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Select Quantity</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Select Quantity"
                                        aria-label="Select Quantity"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={Select_Quantity}
                                        onChange={this.handleChange.bind(this, "Select_Quantity")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <Button onClick={() => this.setState({ showForm: false })} style={{ marginRight: "4px" }} variant="outlined">Cancel</Button>
                                <Button onClick={this.handleSave.bind(this)} style={{ marginLeft: "4px" }} variant="contained" color="success">Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container >
        )
    }
}