import React, { Component } from 'react';
import { Form, InputGroup, Container, Row, Col } from 'react-bootstrap';
import Grid from '../../components/Grid';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Add } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import SaleInvoiceReport from '../../reports/SaleInvoiceReport';

export default class SaleInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            showForm: false,


            SaleList: [],
            ProductList: [],
            CustName: "",
            saleDate: "",

            Item: {
                itemID: "",
                itemName: "",
                itemType: "",
                itemQty: 0,
                itemPrice: 0,
                itemTotAmt: 0
            },

            AddedItemList: []

        }
    }

    componentDidMount() {
        this.getAllSale()
    }

    getAllSale() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/finance/GetAllSales.php`).then(res => {
            let SaleList = []
            if (res.data.success === 1) {
                SaleList = res.data.data
            }
            this.setState({ SaleList })
            this.props.setLoader(false)
        }).catch(err => {
            this.props.setLoader(false)
            console.log('err :>> ', err);
        })
    }

    getAllProduct() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/products/GetAllProducts.php`).then(res => {
            let ProductList = []
            if (res.data.success === 1) {
                ProductList = res.data.data
            }
            this.setState({ ProductList })
            this.props.setLoader(false)
        }).catch(err => {
            this.props.setLoader(false)
            console.log('err :>> ', err);
        })
    }

    handleChange(fieldName, evt) {
        this.setState({ [fieldName]: evt.target.value })
    }

    handleChangeItem(fieldName, evt) {
        let { Item, ProductList } = this.state;
        switch (fieldName) {
            case "itemName":
                let findInventory = ProductList.filter(x => x.ITEM_NAME === evt.target.value)?.[0]
                if (findInventory) {
                    Item['itemID'] = findInventory.ITEM_ID
                    Item['itemType'] = findInventory.ITEM_TYPE
                    Item['itemPrice'] = findInventory.BASIC_PRICE
                }
                break;
            case "itemQty":
                Item['itemTotAmt'] = evt.target.value * Item['itemPrice']
                break;

            default:
                break;
        }

        Item[fieldName] = evt.target.value
        this.setState({ Item })
    }

    renderTotalAmount() {
        let { AddedItemList } = this.state;
        let Price = AddedItemList.map(({ itemTotAmt }) => itemTotAmt).reduce((sum, i) => sum + i, 0);
        return Price
    }

    handleAddItem() {
        let { Item, AddedItemList } = this.state
        // if (!this.handleValidateItem(Item)) return

        let checkIfItemAvailable = AddedItemList.filter(x => x.itemName === Item.itemName)
        let CheckIndex = AddedItemList.findIndex(x => x.itemName === Item.itemName);
        if (checkIfItemAvailable.length > 0) {
            let AddedItemContent = checkIfItemAvailable[0]
            let CalculatedQuantity = parseInt(AddedItemContent['itemQty']) + parseInt(Item['itemQty']);
            AddedItemList[CheckIndex]['itemType'] = Item['itemType'];
            // AddedItemList[CheckIndex]['ItemDisc'] = Item['ItemDisc'];
            // AddedItemList[CheckIndex]['ItemDisType'] = Item['ItemDisType'];
            AddedItemList[CheckIndex]['itemPrice'] = Item['itemPrice'];
            AddedItemList[CheckIndex]['itemQty'] = CalculatedQuantity;

            // if (Item['ItemDisType'] === 'number') {
            Item['itemTotAmt'] = CalculatedQuantity * Item['itemPrice']
            // } else {
            //     Item['itemTotAmt'] = (CalculatedQuantity * Item['itemPrice']) - ((CalculatedQuantity * Item['itemPrice']) * (Item['ItemDisc'] / 100))
            // }

            AddedItemList[CheckIndex]['itemTotAmt'] = parseInt(Item['itemTotAmt']);
            this.setState({ AddedItemList })
        } else {
            let UpdateItemList = [Item].concat(AddedItemList)
            this.setState({ AddedItemList: UpdateItemList })
        }
        let RefreshItem = {
            itemID: "",
            itemName: "",
            itemType: "",
            itemQty: 0,
            itemPrice: 0,
            itemTotAmt: 0
        }
        this.setState({
            Item: RefreshItem
        })

    }

    handleSubmit(evt) {
        evt.preventDefault();
        let formData = {
            CustName: this.state.CustName,
            saleDate: this.state.saleDate,
            Item: JSON.stringify(this.state.AddedItemList),
            TotalAmt: this.renderTotalAmount()
        }
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/finance/CreateSale.php`, formData).then(response => {
            this.props.setLoader(false)
            if (response.data.status === 200) {
                this.props.setAlert(true, "Success", response.data.message, "success")
                this.handleClear();
                this.getAllSale();
                this.setState({ showForm: false })
            } else {
                window.alert(response.data.message);
            }
        }).catch(error => {
            this.props.setLoader(false)
            console.error(error);
        });

    }

    handleClear() {
        this.setState({
            ProductList: [],
            CustName: "",
            saleDate: "",

            Item: {
                itemID: "",
                itemName: "",
                itemType: "",
                itemQty: 0,
                itemPrice: 0,
                itemTotAmt: 0
            },

            AddedItemList: []
        })
    }

    handleEdit(obj) {
        if (obj) {
            this.setState({
                isEdit: true,
                showForm: true,
                CustName: obj.CUST_NAME,
                saleDate: obj.SALE_DATE,
                AddedItemList: JSON.parse(obj.SALE_ITEM)
            })
        }
    }

    render() {
        let {
            showForm,
            CustName,
            saleDate,
            Item,
            isEdit
        } = this.state;
        return (
            <Container>
                {!showForm ?
                    <Grid
                        insertData={() => { this.getAllProduct(); this.setState({ isEdit: false, showForm: true }); this.handleClear() }}
                        handleEdit={(obj) => this.handleEdit.bind(this, obj)}
                        buttonTitle="Create Sale"
                        columnAlt={["Sale Date", "Customer Name", "Total Amount"]}
                        columnTitle={["SALE_DATE", "CUST_NAME", "TOTAL_AMT"]}
                        columnData={this.state.SaleList}
                    />
                    :
                    <div>
                        <Row>
                            <Col md={8} >
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Customer Name</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Customer Name"
                                        aria-label="Customer Name"
                                        aria-describedby="basic-addon1"
                                        value={CustName}
                                        disabled={isEdit}
                                        onChange={this.handleChange.bind(this, "CustName")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={4}>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Sale Date</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Sale Date"
                                        aria-label="Sale Date"
                                        aria-describedby="basic-addon1"
                                        type="date"
                                        disabled={isEdit}
                                        value={saleDate}
                                        onChange={this.handleChange.bind(this, "saleDate")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        {!isEdit &&
                            <><br></br>
                                <Row>
                                    <Col md={11}>
                                        <div
                                            style={{
                                                padding: "10px",
                                                background: "#f1f1f1",
                                                border: "1px solid black",
                                                borderRadius: "5px"
                                            }}
                                        >
                                            <Row>
                                                <Col md={8}>
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon1">Item</InputGroup.Text>
                                                        <Form.Select value={Item['itemName'] || 'Select Item'} onChange={this.handleChangeItem.bind(this, "itemName")}>
                                                            <option value={Item['itemName'] || ''}>{Item['itemName'] || 'Select Item'}</option>
                                                            {this.state.ProductList.map((x, idx) => {
                                                                return (
                                                                    <option key={idx} value={x.ITEM_NAME}>{x.ITEM_NAME}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </InputGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon1">Item Desciption</InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Item Desciption"
                                                            aria-label="Item Desciption"
                                                            disabled
                                                            aria-describedby="basic-addon1"
                                                            value={Item['itemType']}
                                                            onChange={this.handleChangeItem.bind(this, "itemType")}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon1">Quantity</InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Quantity"
                                                            aria-label="Quantity"
                                                            aria-describedby="basic-addon1"
                                                            type="number"
                                                            // disabled
                                                            value={Item['itemQty']}
                                                            onChange={this.handleChangeItem.bind(this, "itemQty")}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon1">Basic Price</InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Basic Price"
                                                            aria-label="Basic Price"
                                                            aria-describedby="basic-addon1"
                                                            type="number"
                                                            disabled
                                                            value={Item['itemPrice']}
                                                            onChange={this.handleChangeItem.bind(this, "itemPrice")}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup>
                                                        <InputGroup.Text id="basic-addon1">Total Amount</InputGroup.Text>
                                                        <Form.Control
                                                            placeholder="Total Amount"
                                                            aria-label="Total Amount"
                                                            aria-describedby="basic-addon1"
                                                            // type="number"
                                                            disabled
                                                            value={Item['itemTotAmt']}
                                                        // onChange={this.handleChangeItem.bind(this, "itemTotAmt")}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={1} style={{ alignContent: "center" }} >
                                        <Button onClick={this.handleAddItem.bind(this)} variant="contained" endIcon={<Add />}>
                                            Add
                                        </Button>
                                    </Col>
                                </Row>
                            </>}
                        <br></br>
                        <Row
                            style={{
                                height: "30vh",
                                overflow: "auto"
                            }}
                        >
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '25vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Description</TableCell>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI/Barcode</TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Quantity</TableCell>
                                                {/* <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Discount</TableCell> */}
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                {/* {!this.state.isEdit ?
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                        <IconButton aria-label="Edit Data" component="span">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    :
                                                    ""
                                                } */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.AddedItemList.map((ItemDetails, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell >{ItemDetails.itemName}</TableCell>
                                                    <TableCell >{ItemDetails.itemType}</TableCell>
                                                    {/* <TableCell >{ItemDetails.ItemIMEI}</TableCell> */}
                                                    <TableCell >£ {ItemDetails.itemPrice}</TableCell>
                                                    <TableCell >{ItemDetails.itemQty}</TableCell>
                                                    {/* <TableCell >{ItemDetails.ItemDisc === "0" || ItemDetails.ItemDisc === 0 ? "" : `${ItemDetails.ItemDisType === 'number' ? '£' : '%'} ${ItemDetails.ItemDisc}`}</TableCell> */}
                                                    <TableCell >£ {ItemDetails.itemTotAmt}</TableCell>
                                                    {/* {!this.state.isEdit ?
                                                        <TableCell >
                                                            <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemove.bind(this, idx)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        : ""
                                                    } */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* <TableContainer> */}
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            {/* <TableCell colSpan={3} /> */}
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} rowSpan={5} colSpan={2}>Net Sale</TableCell>
                                            <TableCell>£ {this.renderTotalAmount()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                            </Paper>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <div style={{ display: "flex", justifyContent: "space-between" }} >
                                    <Button onClick={() => this.setState({ showForm: false })} style={{ marginRight: "4px" }} variant="outlined">Cancel</Button>
                                    <div>
                                        {isEdit &&
                                            <ReactToPrint
                                                trigger={() => { return <Button style={{ background: '#ff7f19', color: "white" }}  >Print</Button> }}
                                                content={() => this.printThermalSale}
                                            />
                                        }
                                        {!isEdit &&
                                            <Button onClick={this.handleSubmit.bind(this)} style={{ marginLeft: "4px" }} variant="contained" color="success">Save</Button>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <SaleInvoiceReport ref={el => (this.printThermalSale = el)}
                            data={{
                                ItemList: this.state.AddedItemList,
                                SaleDate: this.state.saleDate,
                                SaleTo: this.state.CustName,
                                TotalAmount: this.renderTotalAmount(),
                                OTHER_DATA: JSON.stringify([])
                            }}
                        />

                    </div>
                }
            </Container>
        )
    }
}