import React, { Component } from 'react';
import { Form, InputGroup, Container, Row, Col } from 'react-bootstrap';
import Grid from '../../components/Grid';
import { Button } from '@mui/material';
import axios from 'axios';

export default class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            InventoryList: [],
            ProductList: [],
            itemID: "",
            itemName: "",
            itemCode: "",
            itemCategory: "",
            itemStatus: "",
            itemType: "",
            itemPrice: "",
            sellPrice: "",
            Total_CTN: "",
            Item_In_CTN: "",
            Total_Quantity: "",
            MSA: ""
        }
    }

    componentDidMount() {
        this.getAllInventory()
    }

    getAllProduct() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/products/GetAllProducts.php`).then(res => {
            let ProductList = []
            if (res.data.success === 1) {
                ProductList = res.data.data
            }
            this.setState({ ProductList })
            this.props.setLoader(false)
        }).catch(err => {
            this.props.setLoader(false)
            console.log('err :>> ', err);
        })
    }

    getAllInventory() {
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/products/GetAllInventory.php`).then(res => {
            let InventoryList = []
            if (res.data.success === 1) {
                InventoryList = res.data.data
            }
            this.setState({ InventoryList })
            this.props.setLoader(false)
        }).catch(err => {
            console.log('err :>> ', err);
            this.props.setLoader(false)
        })
    }
    handleChange(fieldName, evt) {
        // setTimeout(() => {
        if (fieldName === "itemName") {
            this.handleLinkField(evt.target.value)
        }
        if (fieldName === "Total_CTN") {
            let Total_Quantity = 0
            let { Item_In_CTN } = this.state
            Total_Quantity = +evt.target.value * (+Item_In_CTN || 0)
            this.setState({ Total_Quantity })
        }

        this.setState({ [fieldName]: evt.target.value })
        // }, 3000);
    }

    handleLinkField(value) {
        let { ProductList } = this.state;
        let filterData = ProductList.filter(x => x.ITEM_NAME === value)
        if (filterData && filterData.length > 0) {
            this.setState({
                itemID: filterData[0].ITEM_ID,
                itemName: filterData[0].ITEM_NAME,
                itemCode: filterData[0].ITEM_CODE,
                itemCategory: filterData[0].ITEM_CATEGORY,
                itemStatus: filterData[0].ITEM_STATUS,
                itemType: filterData[0].ITEM_TYPE,
                itemPrice: filterData[0].BASIC_PRICE,
                sellPrice: filterData[0].SELL_PRICE,
                Item_In_CTN: filterData[0].PER_CTN_QTY,
                Total_CTN: "",
                Total_Quantity: ""

            })
        }

    }

    handleClear() {
        this.setState({
            itemID: "",
            itemName: "",
            itemCode: "",
            itemCategory: "",
            itemStatus: "",
            itemType: "",
            itemPrice: "",
            sellPrice: "",
            Total_CTN: "",
            Item_In_CTN: "",
            Total_Quantity: "",
            MSA: ""
        })
    }

    handleSubmit(evt) {
        evt.preventDefault();
        let formData = {
            itemID: this.state.itemID,
            itemName: this.state.itemName,
            itemCode: this.state.itemCode,
            itemCategory: this.state.itemCategory,
            itemStatus: this.state.itemStatus,
            itemType: this.state.itemType,
            itemPrice: this.state.itemPrice,
            sellPrice: this.state.sellPrice,
            Total_CTN: this.state.Total_CTN,
            Item_In_CTN: this.state.Item_In_CTN,
            Total_Quantity: this.state.Total_Quantity,
            MSA: this.state.MSA
        }
        this.props.setLoader(true)
        axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/products/AddInventory.php`, formData).then(response => {
            this.props.setLoader(false)
            if (response.data.status === 200) {
                this.props.setAlert(true, "Success", response.data.message, "success")
                this.handleClear();
                this.getAllInventory();
                this.setState({ showForm: false })
            } else {
                window.alert(response.data.message);
            }
        }).catch(error => {
            this.props.setLoader(false)
            console.error(error);
        });

    }


    handleEdit(obj) {
        // if (obj) {
        //     this.setState({
        //         showForm: true,
        //         itemID: obj.ITEM_ID,
        //         itemName: obj.ITEM_NAME,
        //         itemCode: obj.ITEM_CODE,
        //         itemCategory: obj.ITEM_CATEGORY,
        //         itemStatus: obj.ITEM_STATUS,
        //         itemType: obj.ITEM_TYPE,
        //         itemPrice: obj.ITEM_PRICE,
        //         sellPrice: obj.SELL_PRICE,
        //         Total_CTN: obj.TOTAL_CTN,
        //         Item_In_CTN: obj.ITEM_CTN_QTY,
        //         Total_Quantity: obj.TOTAL_QTY,
        //         MSA: obj.ITEM_MSA
        //     })
        // }
    }


    render() {
        let {
            showForm,
            itemName,
            itemCode,
            itemCategory,
            itemStatus,
            itemType,
            itemPrice,
            sellPrice,
            Total_CTN,
            Item_In_CTN,
            Total_Quantity,
            MSA
        } = this.state;
        return (
            <Container>
                {!showForm ?
                    <Grid
                        insertData={() => { this.getAllProduct(); this.setState({ showForm: true }) }}
                        handleEdit={(obj) => this.handleEdit.bind(this, obj)}
                        buttonTitle="Add Inventory"
                        columnAlt={["Image", "Name", "Description", "Category", "Basic Price", "Total CTN", "Item in CTN", "Total Quantity"]}
                        columnTitle={["ITEM_IMG_PATH", "ITEM_NAME", "ITEM_TYPE", "ITEM_CATEGORY", "BASIC_PRICE", "TOTAL_CTN", "PER_CTN_QTY", "TOTAL_QTY"]}
                        columnData={this.state.InventoryList}
                    />
                    :
                    <div>
                        {/* <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Name</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Name"
                                        aria-label="Item Name"
                                        aria-describedby="basic-addon1"
                                        value={itemName}
                                        onChange={this.handleChange.bind(this, "itemName")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br> */}
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item</InputGroup.Text>
                                    <Form.Select value={itemName || 'Select Item'} onChange={this.handleChange.bind(this, "itemName")}>
                                        <option value={itemName || ''}>{itemName || 'Select Item'}</option>
                                        {this.state.ProductList.map((x, idx) => {
                                            return (
                                                <option key={idx} value={x.ITEM_NAME}>{x.ITEM_NAME}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Code</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Code"
                                        aria-label="Item Code"
                                        disabled
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={itemCode}
                                        onChange={this.handleChange.bind(this, "itemCode")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Category</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Category"
                                        aria-label="Item Category"
                                        aria-describedby="basic-addon1"
                                        disabled
                                        value={itemCategory}
                                        onChange={this.handleChange.bind(this, "itemCategory")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Status</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Status"
                                        aria-label="Item Status"
                                        disabled
                                        aria-describedby="basic-addon1"
                                        value={itemStatus}
                                        onChange={this.handleChange.bind(this, "itemStatus")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Item Desciption</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Item Desciption"
                                        aria-label="Item Desciption"
                                        disabled
                                        aria-describedby="basic-addon1"
                                        value={itemType}
                                        onChange={this.handleChange.bind(this, "itemType")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Basic Price</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Basic Price"
                                        aria-label="Basic Price"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        disabled
                                        value={itemPrice}
                                        onChange={this.handleChange.bind(this, "itemPrice")}
                                    />
                                </InputGroup>
                            </Col>
                            {/* <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Sell Pice</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Sell Price"
                                        disabled
                                        aria-label="Sell Price"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={sellPrice}
                                        onChange={this.handleChange.bind(this, "sellPrice")}
                                    />
                                </InputGroup>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <hr></hr>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Total CTN</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Total CTN"
                                        aria-label="Total CTN"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={Total_CTN}
                                        onChange={this.handleChange.bind(this, "Total_CTN")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1"> Total Item In CTN</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Total Item In CTN"
                                        aria-label="Total Item In CTN"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        disabled
                                        value={Item_In_CTN}
                                        onChange={this.handleChange.bind(this, "Item_In_CTN")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Total Quantity</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Total Quantity"
                                        aria-label="Total Quantiy"
                                        aria-describedby="basic-addon1"
                                        disabled
                                        type="number"
                                        value={Total_Quantity}
                                        onChange={this.handleChange.bind(this, "Total_Quantity")}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    <InputGroup.Text id="basic-addon1">Minimun Stock Available</InputGroup.Text>
                                    <Form.Control
                                        placeholder="Minimun Stock Available"
                                        aria-label="Minimun Stock Available"
                                        aria-describedby="basic-addon1"
                                        type="number"
                                        value={MSA}
                                        onChange={this.handleChange.bind(this, "MSA")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                <Button onClick={() => { this.handleClear(); this.setState({ showForm: false }) }} style={{ marginRight: "4px" }} variant="outlined">Cancel</Button>
                                <Button onClick={this.handleSubmit.bind(this)} style={{ marginLeft: "4px" }} variant="contained" color="success">Save</Button>
                            </Col>
                        </Row>
                    </div>
                }
            </Container >
        )
    }
}