import { Search } from '@mui/icons-material';
import { Button, Box } from '@mui/material';
import { Col, Row, Table, Form, Image } from 'react-bootstrap';
import ProductImage from '../assets/images/ProductImage.png'
// import { useState } from "react";

export default function Grid(props) {
    return (
        <div>
            <Row className="justify-content-end" >
                <Col md={4} >
                    <Box style={{ display: 'flex', alignItems: 'flex-end' }} >
                        <Search sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                        />
                    </Box>
                </Col>
                <Col md={{ span: 3, offset: 5 }} className='text-end' >
                    <Button onClick={props.insertData} variant="contained">{props.buttonTitle}</Button>
                </Col>
            </Row>
            <br />
            <div className='p-2' style={{ background: "#f8f9fa", borderRadius: "5px" }}  >
                <Table striped hover>
                    <thead>
                        <tr>
                            <th>Sr.No</th>
                            {props.columnAlt.map((x, idx) => (<th key={idx} >{x}</th>))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.columnData.map((x, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {props.columnTitle.map((c, idx) => {
                                    let tr = "";
                                    if (x.ITEM_IMG_PATH && x.ITEM_IMG_PATH !== "") {
                                        props.handleEdit && idx === 0 ?
                                            tr = <td key={idx} style={{ color: "red", cursor: "pointer" }} onClick={props.handleEdit(x)}  >
                                                <Image thumbnail
                                                    height={100}
                                                    width={100}
                                                    src={`${process.env.REACT_APP_BASE_URL}store/products/${x[c]}`}
                                                    alt='Product Image'
                                                    onError={(e) => {
                                                        if (e.target.src !== ProductImage) {
                                                            e.target.src = ProductImage;
                                                        }
                                                    }}
                                                />
                                            </td>
                                            :
                                            tr = <td key={idx} >{x[c]}</td>
                                    } else {
                                        props.handleEdit && idx === 0 ?
                                            tr = <td key={idx} style={{ color: "#1ca8f0", cursor: "pointer" }} onClick={props.handleEdit(x)}  >{x[c]}</td>
                                            :
                                            tr = <td key={idx} >{x[c]}</td>
                                    }
                                    return tr;

                                }
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}